
import logo from './logo.svg';
import './App.css';
import React,{Suspense} from 'react'
import {Navigate} from "react-router-dom"
import {useRoutes} from "react-router-dom"
import loadable from '@loadable/component'
const DashBoard = loadable(() => import('./pages/DashBoard'))
const Home = loadable(() => import('../src/components/Home'))




function App() {
  
  
      
  let element = useRoutes([
    {
      path:"/",
      element:<Home><DashBoard/></Home>
  },


  {
    path:"*",
    element:<Navigate to="/"/>
  },


])

return element
}
 
export default App;

