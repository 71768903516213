import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import ScrollToTop from './utils/ScroolTop';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
      // <Provider store={store}>
    <BrowserRouter>
    {/* <PersistGate loading={null} persistor={persistor}> */}
    {/* <ScrollToTop/> */}
    <App />
    <ToastContainer/>
    {/* </PersistGate> */}
    </BrowserRouter>
    // </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


// console.clear()
